
    .btn-table {
        padding: 10px 24px;
        text-transform: uppercase;
        

        svg {
        polygon {
            fill: $secondary-base;  
        }
        
        }
        &__option {
        text-transform: uppercase;
        font-size: 12px; 
        text-decoration: underline;
        text-transform: uppercase;
        padding: 0px;
        
        &--cell-action {
          color: $violet;
        }

        &--deactivate-action {
          color: $danger-base;
        }
      }
        
    }
    

    .overview-drawer-button{
        text-transform: uppercase;

        svg {
        polygon {
            fill: $secondary-base;  
        }
        
        }
        &__option {
        min-width: 90px;
        text-transform: uppercase;
        font-size: 12px; 
        text-decoration: underline;
        text-transform: uppercase;
        padding: 0px;
        
        &--cell-action {
          color: $violet;
        }
      }
        
    }
