// Always check COREUI documentation before add a new
// utility class.
// Remove any unused utility class

.break-spaces {
	white-space: break-spaces;
}

.background-color-white {
	background: $white;
}

.overflowy-scroll {
	overflow-y: scroll;
}

.min-width-100 {
	min-width: 100px !important;
}

.no-border {
	border: none !important;
}