.material-with-tag {
    margin-top: 60px;
    border: 3px solid $violet;
}

.material-tag {
    margin-bottom: 30px;
    margin-right: -23px;
    margin-top: -75px;
    display: flex;
    justify-content: flex-end;
    span {
        display: inline-block;
        padding: 8px 16px;
        background-color: $violet;
        color: $white;
        font-weight: 600;
    }
}