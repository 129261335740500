.save-item-delivered {
    padding: 10px 0px;

    svg {
        margin-left: 0px;
    }
}

.line-separator {
    height: 1px;
    width: 100%;
    background: #D9DADB;
    margin-bottom: 50px
}

.delivered-tag {
    margin: 0px 15px 10px 0px;
    padding: 0 10px;
    display: inline-block;
    vertical-align: middle;
    background-color: rgba($green, 0.9);
    color: $black;
    opacity: 0.9;
    border-radius: 20px;
    font-size: $font-size-sm;
}

.delivery--total-prices {

    width: 400px;
    padding: 32px 20px;

    .table {
        td {
            padding: 5px 0;
            color: rgba($black, 0.6);
            font-size: 12px;

            +td {
                text-align: right;
            }
        }

        tr {
            &:nth-last-child(2) {
                td {
                    padding-bottom: 20px;
                }
            }
        }

        .total-price {
            td {
                border-top: 1px solid $yellow;
                font-size: $font-size-base;
                color: $black;
            }
        }
    }
}

.delivery {
    margin-bottom: 25px;
}