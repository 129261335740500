.footer {
  background-color: transparent;
  a {
    font-weight: 400;
    text-decoration: none;
    &:hover {
      color: $secondary-base;
      text-decoration: none;
    }
  }

  .copy-icon {
    margin-left: 10px;
    cursor: pointer;
    &:hover {
      rect {
        stroke: $secondary-base;
      }
    }
  }
}
.footer-top {
  padding-top: 30px;
  padding-bottom: 30px;
  background: $primary-base;
  @include media-breakpoint-up(lg) {
    padding-top: 68px;
    padding-bottom: 110px;
  }

  .logo {
    margin-bottom: 68px;
  }

  .container {
    @include media-breakpoint-up(xxl) {
      max-width: 1237px;
    }
  }
  ul {
    display: inline-block;
    padding: 0;
    margin: 0;
    list-style: none;
  }

  li {
    margin-top: 10px;
    @include media-breakpoint-up(lg) {
      margin-top: 20px;
    }
  }
  a {
    color: $white;
    text-decoration: none;
    font-size: 16px;
    line-height: 16px;
    // @include media-breakpoint-up(md) {
    //     font-size: 16px;
    //     line-height: 16px;
    // }
  }
}

.footer-title {
  min-height: 46px;
  margin-bottom: 5px;
  color: $white;
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;

  @include media-breakpoint-up(lg) {
    min-height: auto;
    margin-bottom: 19px;
    font-size: 20px;
    line-height: 23px;
  }
}

.footer-bottom {
  padding-top: 10px;
  padding-bottom: 10px;
  border: none;
  background-color: rgba($primary-base, 0.9);
  .container {
    @include media-breakpoint-up(xxl) {
      max-width: 1237px;
    }
  }
  @include media-breakpoint-up(lg) {
    padding: 20px 32px;
  }

  a,
  .copyright {
    color: $white;
    font-size: 14px;
  }
}

.footer-bottom-menu {
  padding: 0;
  list-style: none;

  li {
    &:not(:last-child) a {
      padding-right: 16px;
      margin-right: 16px;
      border-right: 1px solid #fff;
    }
  }
  @include media-breakpoint-down(sm) {
    li {
      &:not(:last-child) a {
        padding-right: 0;
        margin-right: 0;
        border-right: 0;
      }
    }
  }
}

.footer-bottom-social {
  padding: 0;
  list-style: none;

  li {
    &:not(:last-child) a {
      padding-right: 8px;
      margin-right: 8px;
    }
  }
}
