.product-upload-button {
  &:hover {
    cursor: pointer;
    span {
      color: $warning-base;
    }
  }
}
.user-section {
  max-width: 70%;
}

.partner-callout {
  border-left-color: $yellow;
}