h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  font-family: $spartan-font;
}

.h1 {
  font-size: $h1-font-size;
}

.h2 {
  font-size: $h2-font-size;
}

.h3 {
  font-size: $h3-font-size;
}

.h4 {
  font-size: $h4-font-size;
}

.h5 {
  font-size: $h5-font-size;
}

.h6 {
  font-size: $h6-font-size;
}

@media screen and (max-width: $screen-sm) {
  .h1 {
    font-size: $h1-font-size * 0.75;
  }

  .h2 {
    font-size: $h2-font-size * 0.75;
  }

  .h3 {
    font-size: $h3-font-size * 0.75;
  }

  .h4 {
    font-size: $h4-font-size * 0.75;
  }

  .h5 {
    font-size: $h5-font-size * 0.75;
  }

  .h6 {
    font-size: $h6-font-size * 0.75;
  }
}

.spartan-font {
  font-family: $spartan-font;
}

.roboto-font {
  font-family: $roboto-font;
}

.gilroy-font {
  font-family: $gilroy-font;
}

.helvetica-font {
  font-family: $helvetica-font;
}
