a {
  &:focus-visible {
    outline: 4px solid rgba($light-violet, 0.6) !important;
  }
  &:focus:not(:focus-visible) {
    outline: none !important;
  }
  &:active {
    outline: 0 !important;
  }
}

.btn {
  &:hover,
  &:focus,
  &:active {
    outline: 0;
    box-shadow: none !important;
    svg {
      path {
        stroke: $black;
      }
      circle {
        fill: $black;
      }
    }
  }

  &:focus-visible {
    outline: 4px solid rgba($light-violet, 0.6) !important;
  }

  .spinner-grow {
    position: static;
  }
}

.btn-outline-primary {
  &:hover,
  &:active {
    color: $primary-base;
    background: $secondary-base;
  }

  &:focus-visible {
    color: $primary-base;
    background-color: $yellow;
  }
}

.btn-primary {
  border: none;
  &:hover,
  &:active {
    color: $primary-base;
    background: $secondary-base;
  }

  &:focus-visible {
    color: $primary-base;
    background-color: $yellow;
  }
}
