.bIncrementDecrement-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid rgba($gray-base, 0.3);
  border-radius: 4px;
  max-height: 50px;

  .selected-item {
    width: 155px;
    padding: 12px;
    border-right: 1px solid rgba($gray-base, 0.3);
    text-align: center;
  }
}

.dropdown-icon,
.icon-decrement,
.icon-increment {
  padding: 12px;
  cursor: pointer;

  &.disabled {
    pointer-events: none;
    background-color: rgba($gray-base, 0.3);
  }
}

.count {
  border-left: 1px solid rgba($gray-base, 0.3);
  border-right: 1px solid rgba($gray-base, 0.3);
  text-align: center;
  .not-allowed {
    border: 2px solid $danger-base;
  }
  input {
    padding: 12px 10px;
    width: 120px;
    border: none;
    text-align: center;
    appearance: textfield;
  }
}
input.number-to-text::-webkit-outer-spin-button,
input.number-to-text::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
