.backoffice-modal {
  &__form {
    min-height: 700px;
  }
}

.show-all-columns,
.show-default-columns {
  padding: 6px 12px;
  border: 1px solid $primary-base;
  border-radius: 4px;
}

.backoffice-sidebar-active {
  width: 5px;
  height: 51px;
  background-color: $secondary-base;
  position: absolute;
  left: 0;
}
.rbc-event {
  border-radius: 0;
  text-align: center;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 70px;
}

.calendar-background {
  background-color: $yellow !important;
}
.calendar-event {
  background-color: $yellow !important;
  border: none !important;
  color: $badge-color !important;
  font-weight: 500;
  padding-top: 5% !important;
}
.rbc-toolbar {
  margin-bottom: 1.5rem !important;
  .rbc-toolbar-label {
    font-size: 1.5rem;
    font-weight: 500;
    color: $primary-base;
  }
}
.calendar-outline {
  border: 1px solid $border-color;
}
