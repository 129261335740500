.password-field-wrapper {
    .form-control {
      padding-right: 36px;
      &.is-invalid {
        background-position: right 32px center;
      }
    }
    li {
      align-items: center;
      margin: 4px 0;
      span {
        opacity: 1;
        padding-top: 0;
        color: rgba($primary-base, 0.6);
        font-size: 14px;
      }
      svg {
        padding: 3px;
        border-radius: 50%;
        path {
          fill: $white;
        }
      }
      &.invalid {
        svg {
          border: 1px solid $gray-base;
        }
      }
      &.valid {
        svg {
          background-color: $success-base;
        }
      }
    }
    div {
      svg {
        cursor: pointer;
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
}

.form-check {
  margin-bottom: 22px;
}

.form-check-label {
  font-size: $font-size-sm;
}

.form-check-input {
  cursor: pointer;
}