.material-view-totals {
    padding: 28px 16px;
}

.material-view-warning {
    margin-top: 10px;
    padding: 14px 12px;
    border-radius: 6px;
    background-color: rgba($yellow, 0.6);
    svg {
        flex-shrink: 0;
    }
    p {
        margin-left: 8px;
        margin-bottom: 0;
        font-size: $font-size-sm;
    }
}

.info-costs-clickable {
    cursor: pointer;
    text-decoration: underline;
}

.info-costs-table-row {
    width: 200px;
}