.progress-container {
  width: 250px;
  color: #363a43;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  height: 19px;
  font-weight: normal;
  font-size: 14px;
  color: #363a43;
}

.file-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 200px;
}

.upload-list {
  display: flex;
  gap: 10px;
  align-items: center;
  background: #f0f0f0;
  border: 0.1rem solid #cfdbde;
  border-radius: 0.2rem;
  padding: 10px;
  width: 550px;
}

.file-size {
  color: #7eb631;
}

#upload-button {
  background-color: indigo;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  font-family: sans-serif;
  border-radius: 0.3rem;
  cursor: pointer;
  font-size: 16px;
}

#upload-button:hover {
  opacity: 0.8;
}

.progress-bar-width {
  width: 250px;
}

.products-container {
  max-height: 85vh !important;
  overflow-y: scroll;
}

.partners-container {
  overflow-y: hidden;
  max-height: 88vh !important;
}

.form-select:focus {
  box-shadow: none;
  outline: 3px solid yellow !important;
}

.partners-info-domain {
  font-size: 0.9rem;
}

.partners-info-id {
  font-size: 0.8rem;
}