.categories-image-wrap {
  pointer-events: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  margin-right: 10px;
  img {
    border-radius: 50%;
  }
}

.categories-title {
  margin-bottom: 19px;
  font-weight: 500;
  font-size: 28px;
}

.categories-list-section {
  padding: 18px 0;
  @include media-breakpoint-up(xl) {
    padding: 45px 0 37px;
  }
}

.categories-list-section-top {
  padding: 0 15px;
  @include media-breakpoint-up(xl) {
    padding: 0 45px;
  }
}

.categories-dropdown {
  margin-top: 16px;
  width: 100%;
  position: static;
  &.show {
    .categories-dropdown-toggle {
      color: $primary-base;
      text-shadow: 0px 0px 1px $primary-base;
      border-left-color: $yellow !important;
      &:focus {
        outline: none;
      }
    }
    .categories-image-wrap {
      img {
        background-color: $yellow;
      }
    }
    .mobile-close-text {
      color: $violet;
      background-color: rgba($violet, 0.2);
      border-radius: 18px;
      margin-right: 10px;
    }
  }
  .categories-dropdown-toggle {
    display: block;
    width: 100%;
    padding: 0 15px;
    box-shadow: none !important;
    background-color: transparent;
    border: none;
    border-left: 6px solid transparent;
    border-radius: 0;
    color: $primary-base;
    font-size: 16px;
    text-align: left;
    white-space: normal;
    @include media-breakpoint-up(xl) {
      padding: 0 45px;
    }
    &:hover {
      border-left-color: $yellow !important;
      text-shadow: 0px 0px 1px $primary-base;
      .categories-image-wrap {
        img {
          background-color: $yellow;
        }
      }
    }

    &:focus {
      background-color: transparent;
      border-left-color: transparent;
      text-shadow: none;
      outline: 3px solid $violet;
    }

    &:after {
      display: none;
    }
  }
  .dropdown-menu {
    padding: 0;
    margin-left: 10px;
    min-width: 350px;
    box-shadow: 0px 4px 10px rgba(175, 187, 194, 0.3);
    @media only screen and (max-width: 1280px) {
      max-width: 600px;
      height: 500px;
      overflow-y: auto;
    }
    @include media-breakpoint-up(xl) {
      max-width: 913px;
      height: 500px;
      overflow-y: auto;
    }
    @include media-breakpoint-down(sm) {
      height: auto;
    }
  }

  .dropdown-item {
    padding: 9px 21px;
    color: $black;
    font-weight: 400;
    font-size: 16px;
    &:focus {
      background-color: transparent;
      outline: 3px solid $violet;
    }
    &:hover,
    &.active {
      background-color: $yellow;
      outline: none;
    }
  }
}

.categories-group-title {
  margin: 0;
  border-bottom: 1px solid rgba($primary-dark, 0.12);
  span {
    padding: 14px 21px;
    display: block;
    font-weight: 600;
    font-size: 20px;
    line-height: 1;
    cursor: pointer;
    &:hover,
    &.active {
      background-color: $yellow;
    }
    &:focus-visible {
      background-color: transparent;
    }
  }
}

.categories-submenu-group {
  padding: 22px 33px;
  span {
    cursor: pointer;
  }
}

.subCategory {
  display: block;
  margin-bottom: 18px;
  color: $black;
  font-weight: 600;
  font-size: 16px;
  overflow-wrap: break-word;
  text-transform: uppercase;
}

.subsubCategory {
  margin-bottom: 18px;
  span + span {
    color: $black;
    font-weight: 400;
    cursor: pointer;
  }
}
