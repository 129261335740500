.calendar-cell:hover:not(.disabled) .calendar-cell-inner,
.calendar-cell:hover:not(.disabled),
.calendar-cell:not(.disabled):not(.next):not(.previous):hover .calendar-cell-inner, .calendar-cell.clickable:hover .calendar-cell-inner {
    background-color: $yellow;
}

.calendar-cell.selected:not(.previous):not(.next),
.calendar-cell.selected:not(.previous):not(.next).start,
.calendar-cell.selected:not(.previous):not(.next).end,
.calendar-cell.selected:not(.previous):not(.next) .calendar-cell-inner,
.calendar-cell.selected:not(.previous):not(.next).start .calendar-cell-inner,
.calendar-cell.selected:not(.previous):not(.next).end .calendar-cell-inner {
    background-color: $table-striped-bg;
    color: $black;
}

.calendar {
    .calendar-header-cell-inner {
        color: $black;
    }
}

.picker-footer {
    padding: 18px 0 0 0;
    border-top: 1px solid #dedff0;
    display: flex;
    justify-content: flex-end;
    > .btn.me-auto {
        display: none;
    }

    .btn + .btn {
        margin-left: 0;
    }
    .btn-sm {
        --cui-btn-padding-x: 2rem;
        --cui-btn-padding-y: 0.625rem;
    }

    .btn-ghost-primary {
        border: none;
        text-decoration: underline;
        &:focus, &:hover {
            background-color: transparent;
            color: $black;
            border: none;
        }
    }
}

.date-picker {
    .dropdown-menu.show {
        padding: 24px;
        border: none;
        box-shadow: $box-shadow;
    }
}

.datepicker-cell {
    .input-group {
        flex-direction: row-reverse;
    }

    .picker.show .input-group {
        border: none;
        background-color: transparent;
    }

    .form-control {
        padding: 0;
        border-radius: 0;
        border: none;
        background-color: transparent;
        &:focus-visible {
            background-color: transparent;
        }
    }

    .input-group-text {
        margin-right: 10px !important;
        padding: 0;
        background-color: transparent !important;
        border: none;
    }

    &.datepicker-cell-offer-validity {
        border: 2px solid #EBEBF2;
        padding: 0 10px;
        width: 160px;
        margin-right: 10px;
        
        .input-group {
            flex-direction: row;
        }
    }
}

.time-picker-body {
    .form-select {
        background-image: none !important;
        border: 0;
        padding-left: .25rem !important;
        padding-right: .25rem !important;
        text-align: right;
        width: auto;
    }
}