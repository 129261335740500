.breadcrumb-wrapper {
    margin-bottom: 20px;
    margin-top: 20px;
    @include media-breakpoint-up(lg) {
        margin-top: 30px;
        margin-bottom: 30px;
    }
}

.breadcrumb {
    margin: 0;
}

.breadcrumb-item {
    a {
        text-decoration: none;
    }
    &.active {
        color: $black;
        font-weight: 500;
    }
}