.search-input-wrapper {
    position: relative;
    svg {
        position: absolute;
        top: 15px;
        left: 15px;
    }

    .form-control {
        padding-left: 40px;
        background: $white;
    }

    .search-input {
        border: 1px solid var(--cui-input-border-color, #dedff0);
    }
}