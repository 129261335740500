.form-check-label {
    cursor: pointer;
}

.form-check-input:checked[type=checkbox] {
    background-image: var(--cui-form-check-input-checked-bg-image, url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27rgba%2825, 23, 27, 1%29%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%273%27 d=%27m6 10 3 3 6-6%27/%3e%3c/svg%3e"));
}

.form-check-input {
    &:checked {
        background-color: $secondary-base;
    }
    &:focus {
        box-shadow: none;
    }
}