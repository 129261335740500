@import '_product-view--units-quantity';
@import '_product-view--documents';
@import '_product-view--details';

.product-view--page {
    min-height: 80vh;
}

.product-view--section {
    padding: 25px 20px;
    background: $white;
    border-radius: 6px;
}

.small-title {
    margin: 0;
    color: rgba($black, 0.6);
    font-weight: 300;
    font-size: 14px;
    @include media-breakpoint-up(xl) {
        font-size: 1rem;
    }

}

.product-view--image {
    margin-bottom: 12px;
    position: relative;
    max-height: 50vh;
    .carousel {
        position: static;
    }

    .img-thumbnail {
        padding: 0;
        border: none;
        background-color: white;
        object-fit: contain;
    }
}

.carousel-control-prev, .carousel-control-next {
    top: 50%;
    transform: translateY(-50%);
    bottom: auto;
    width: 4%;
}

.carousel-control-next {
    right: 20px;
}

.carousel-control-prev {
    left: 20px;
}

.product-view-tabs {
    padding: 10px 14px 20px;
    .see-all-product-details {
        display: none;
    }

    .nav-item, .tab-pane {
        &:last-child {
            display: none;
        }
    }
}

.product-view--info {
    margin-bottom: 20px;
    padding-bottom: 9px;
    .d-flex {
        margin-bottom: 24px;
    }
    p {
        margin: 0;
        color: rgba($black, 0.6);
        font-weight: 400;
        svg {
            margin-left: 15px;
        }
    }
}

.product-view--prices {
    margin-bottom: 16px;

    .save-item {
        margin-top: 18px;
    }
}

.product-view--price {
    margin-top: 24px;
    padding-top: 30px;
    border-top: 1px solid rgba(137, 137, 147, 0.2);
}

.product--price {
    font-weight: 600;
    font-size: 26px;
    line-height: 33px;
    @include media-breakpoint-up(lg) {
        font-size: 16px;
    }
    @include media-breakpoint-up(xxl) {
        font-size: 26px;
    }
}

.product--price-small {
    margin-top: 16px;
    font-weight: 400;
    color: rgba($black, 0.6);
}