.delivery-details--wrapper {
  @include media-breakpoint-up(xxl) {
    padding-left: 50px;
  }
}

.delivery-details--title {
  margin-bottom: 17px;
  margin-top: 20px;
  cursor: pointer;
  font-weight: 600;
  font-size: $font-size-base;
  svg {
    margin-left: 15px;
  }
}

.material-delivery-details {
  &.details-opened {
    .delivery-details--title {
      svg {
        rotate: 180deg;
      }
    }
  }
}

.material-delivery-details {
  .delete {
    cursor: pointer;
  }
}

.delivery-details--table {
  .table-responsive {
    overflow: visible;
  }

  .delivery-details--table-content {
    > thead {
      background-color: $table-striped-bg;
      border-bottom: 1px solid $border-color;
      th {
        vertical-align: middle;
        padding: 6px 18px;
        font-size: $font-size-sm;
        font-weight: 500;
      }

      + tbody {
        td:first-child,
        td:nth-child(2) {
          padding: 8px;
        }
        td,
        th {
          padding-left: 18px;
          padding-right: 18px;
          vertical-align: middle;
        }
      }
    }
  }

  .form-check {
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .datepicker-cell {
    min-width: 210px;
  }

  .address-cell {
    p {
      width: 150px;
    }
    @include media-breakpoint-up(xxl) {
      p {
        width: 210px;
      }
    }
  }

  .quantity-cell {
    .form-control {
      max-width: 80px;
      margin: 0 auto;
      padding: 0;
      border: none;
      border-radius: 0;
      border-bottom: 1px solid $border-color;
      background-color: transparent;
      text-align: right;
    }
  }
}
