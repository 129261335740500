.product-view--documents {
    .container {
        max-width: calc(100% - 20px);
    }

    .product-view--document-icon {
        padding: 8px;
        background: #F7F7FC;
        border-radius: 5px;
    }

    .product-view--document-name {
        margin: 0;
        color: rgba($black, 0.6);
        font-size: $font-size-sm;
        font-weight: 400;
    }
}

.product-view--document {
    position: relative;
    margin-top: 10px;
    padding: 9px;
    box-shadow: $box-shadow;
    border-radius: 5px;
    > a {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }

    &:hover {
        .product-view--document-icon {
            background-color: $yellow;
        }
        svg {
            path {
                fill: $black;
            }
        }
    }

    &:focus {
        outline: 3px solid $violet;
    }
}

.product-view-documents-title {
    padding-bottom: 23px;
    margin-bottom: 26px;
    border-bottom: 1px solid rgba(140, 140, 140, 0.8);
}