@import '_material--delivery-details';
@import '_material--total-prices';
@import '_material--note-section';

.material-img {
  margin: 0 19px;
}

.material--title {
  font-size: $font-size-base;
  font-weight: 600;
  max-width: 75%;
}

.material--feature {
  span {
    color: $black;
    font-size: $font-size-sm;
  }

  .material--feature-title {
    margin: 0 8px 0 0;
    color: rgba($black, 0.6);
    font-size: $font-size-sm;
  }
}

.delete-item,
.save-item {
  cursor: pointer;
}

.fit-content {
  max-width: fit-content;
}

.loading-height {
  height: 60px;
}

.font-semibold {
  font-weight: 600;
}

.material {
  margin-bottom: 40px;
  padding: 33px 20px 20px 20px;
  background-color: $white;

  #quantity {
    min-width: 94px;
    max-width: 95px;
    background-color: $white;
  }

  #units {
    margin-left: 12px;
    min-width: 114px;
    min-height: initial;
    background-color: $white;
  }

  .delete-item,
  .save-item {
    margin-top: 19px;
    color: $violet;
    text-decoration: underline;

    svg {
      margin-right: 8px;
    }
  }

  .save-item-only {
    margin-top: 19px;
    color: $violet;
    text-decoration: underline;

    svg {
      margin-right: 8px;
    }
  }
}

.quantity-added {
  margin-top: 8px;
  color: $violet;
  font-weight: 400;
  font-size: 12px;
}

.material-total {
  margin-top: 14px;
  color: rgba($black, 0.8);
  text-align: right;
  font-weight: 700;

  span {
    color: rgba($gray-base, 0.8);
    font-size: 12px;
  }
}

.catalog-price {
  color: rgba($black, 0.6);
  font-size: 12px;
  font-weight: 400;
  text-align: right;

  span {
    text-decoration: line-through;
  }
}

.status-tag {
  margin: 15px 0px 15px 0px;
  padding: 0 10px;
  display: inline-block;
  vertical-align: middle;
  color: $black;
  opacity: 0.9;
  border-radius: 20px;
  font-size: $font-size-sm;
}

.material-bottom--section {
  margin-top: 56px;
  padding: 32px 20px;
  border-top: 1px solid $border-color;
}

// .form-control:disabled {
//     background-color: transparent !important;
// }

.material-filter-option {
  margin: 0 5px;
  padding: 10px 15px;
  background-color: $table-striped-bg;
  color: $violet;
  text-decoration: underline;
  cursor: pointer;
}

.material-filter-item-material-surcharges-multiplier {
  width: 200px;
}

.material-filter-item-material-surcharges-surcharge-name {
  width: 300px;
}

.material-filter-item-material-surcharges-cost {
  width: 105px;
}
.material-adjust-price-input {
  width: 100%;
}
.material-filter-item-material-surcharges-unit {
  width: 120px;
}

.material-filter-add-button {
  cursor: pointer;
}

.material-filter-add-button-empty {
  min-width: 38px;
}

.material-filter-delete-button {
  cursor: pointer;
}

.material-filter-dropdown-delivery {
  font-size: 13px;
}

.material-filter-dropdown-selected {
  width: 240px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.material-filter-dropdown .selected-item {
  width: 230px;
}

.material-filter-cost {
  min-width: 88px;
}

.material-filter-unit .selected-item {
  min-width: 60px;
}

.material-filter-delivery-wrapper .col {
  padding: 0 8px;
}

.delivery-surcharges-modal .modal-dialog {
  --cui-modal-width: 1350px;
}

.material-surcharges-modal .modal-dialog {
  --cui-modal-width: 880px;
}

.material-filter-item-delivery-costs-multiplier {
  width: 520px;
}

.material-fix-form-select-error {
  width: auto !important;
  display: block;
}

.material-product-changed {
  background-color: #fff6cc !important;
}
