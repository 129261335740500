.cost-adjustments {
    padding: 24px 0;
    border-top: 1px solid $border-color;
}

.cost-adjustment {
    margin-top: 16px;
    padding: 16px;
    background-color: #F7F7FC;
    border: 1px solid $border-color;
}

.cost-adjustment-description {
    margin-left: 20px;
    .cost-adjustment-title {
        font-weight: 600;
        text-transform: uppercase;
    }
    p {
        margin-top: 16px;
        font-size: $font-size-sm;
    }
}

.cost-adjustment-buttons {
    margin-top: 16px;
    gap: 10px;
    div {
        color: $purple;
        text-decoration: underline;
        cursor: pointer;
    }
}

.add-additional-adjustment {
    display: inline-block;
    padding: 7px 25px;
    cursor: pointer;
    color: $purple;
    background-color: #F7F7FC;
    text-decoration: underline;
    svg {
        margin-right: 10px;
    }
    &.disabled {
        pointer-events: none;
        opacity: 0.65;
    }
}