@import '_projectMaterial-preview-item';

.basket-icon {
    &.is-projectMaterialPreviewVisible, &.is-projectMaterialPreviewDetailsVisible, &:hover {
        svg {
            path {
                stroke: $yellow;
            }
        }
        circle {
            fill: $yellow;
        }
    }
}

.offcanvas--projectMaterialPreview {
    margin-top: 96px;
    border: none !important;
    overflow: hidden;

    .offcanvas-body {
        padding: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}

.projectMaterialPreview-checkout {
    padding: 24px 10px;
    box-shadow: $box-shadow;
    background-color: $white;
}