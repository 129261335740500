.form-control {
    height: 48px;
    min-height: 48px !important;
    padding-left: 16px;
    padding-right: 16px;
    &:focus {
        outline: 4px solid rgba($light-violet, 0.6);
    }
    &.is-valid {
        background-size: 13px 13px;
    }
}

.react-tel-input {
    .form-control {
        border-radius: 0 !important;
        height: 48px !important;
    }
}