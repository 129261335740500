.shopping-list-add-details--selected-title {
    font-size: 1.425rem;
    font-weight: normal;
}

.shopping-list-add-details--item {
    #quantity {
        min-width: 94px;
        background-color: $white;
    }
    #units {
        margin-left: 12px;
        min-width: 114px;
        background-color: $white;
    }
}

.shopping-list-add-details--price-column {
    min-width: 180px;
}

.shopping-list-add-details--unit-price-column {
    margin-right: 10px;
}

.shopping-list-add-details--item-total-title {
    text-align: right;
    color: rgba($black, 0.6);
    font-size: $font-size-sm;
}

.shopping-list-add-details--item-total {
    color: rgba($black, 0.8);
    text-align: right;
    span {
        color: rgba($gray-base, 0.8);
        font-size: 12px;
    }
}


.shopping-list-add-details--details-address {
    font-size: 14px !important;
    padding: 5px;
}

.shopping-list-add-details--details-terms {
    font-size: 14px !important;
    padding: 5px;
}

.shopping-list-add-details {
    .datepicker-cell {
        border: 1px solid rgba(130, 132, 134, 0.3);
        border-radius: 4px;
        padding: 12px;
    }
}