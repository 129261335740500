.top-bar-context {
	position: relative;
	min-width: 33%;

	&:focus-within,
	.no-project-selected {
		outline: 4px solid rgba($light-violet, 0.6);
	}

	&__toggle-btn {
		min-height: 64px;
		padding: 10px 16px;
		height: 100%;
		cursor: pointer;
		> div {
			flex-grow: 1;
		}
	}

	&__toggle-btn-icon--active {
		transform: rotate(.5turn)
	}

	&__placeholder--active {
		font-size: 14px;
	}

	&--address {
		background-color: $secondary-base;
	}

	&__list-item {
		position: relative;
		cursor: pointer;

		&--active {
			font-weight: bold;

			&:before {
				position: absolute;
				left: 0;
				top: 0;
				bottom: 0;
				content: '';
				display: block;
				background-color: #75768A;
				width: 6px;
				margin: 8px 0;

			}
		}

		&--odd {
			background-color: #eeeeee;
		}

		&--underline {
			text-decoration: underline;
		}

		&:hover {
			background-color: $secondary-base;
			font-weight: bold;

			&:before {
				display: none;
			}
		}
	}

	&__p-info {
		font-size: 14px;
	}

	&__disabled-p-info {
		font-size: 14px;
	}

	.top-bar-context-items {
		position: absolute;
		left: 1px;
		top: 65px;
		right: 0;
		background: $white;
		max-height: calc(100vh - 35vh);
		z-index: 999;
		overflow-y: scroll;
	}
}

.add-new-project {
	border-top: 1px solid $border-color;
	text-align: center;
}