.form-floating {
  > label {
    padding: 11px 16px;
    opacity: 0.65;
    right: 0;
    font-family: $font-family-sans-serif;
  }

  #quantity {
    + label {
      padding: 11px 8px;
      width: 130px;
    }
  }

  > .form-control:focus {
    + label {
      right: auto;
    }
  }

  > .form-select,
  > .form-control {
    padding: 17px 40px 5px 10px;
    height: $input-height;
  }
  > .form-control-backoffice {
    padding: 17px 16px 5px;
    height: $input-height;
    border-radius: 5px;
  }

  > .form-control:not(:placeholder-shown) {
    padding: 17px 16px 5px;
  }

  #units {
    + label {
      padding: 11px 10px 11px 26px;
      opacity: 1;
    }
  }
  > .form-control:disabled {
    background-color: #f0f0f0;
  }
}

.form-select:focus {
  box-shadow: none;
  outline: 3px solid $violet;
}
.form-select:disabled {
  background-color: #f0f0f0;
}
.was-validated .form-control:valid,
.form-control.is-valid,
.was-validated .form-control:invalid,
.form-control.is-invalid {
  background-position: right 16px center;
}

.invalid-feedback {
  display: inline;
  position: relative;
  text-align: right;
}

.form-floating > :disabled ~ label::after,
.form-floating > .form-control:disabled ~ label::after {
  background-color: transparent;
}
