//@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@font-face {
    font-family: 'Spartan';
    src: url('../../fonts/Spartan/Spartan-Bold.woff2') format('woff2'),
        url('../../fonts/Spartan/Spartan-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Spartan';
    src: url('../../fonts/Spartan/Spartan-Thin.woff2') format('woff2'),
        url('../../fonts/Spartan/Spartan-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Spartan';
    src: url('../../fonts/Spartan/Spartan-Black.woff2') format('woff2'),
        url('../../fonts/Spartan/Spartan-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Spartan';
    src: url('../../fonts/Spartan/Spartan-Light.woff2') format('woff2'),
        url('../../fonts/Spartan/Spartan-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Spartan';
    src: url('../../fonts/Spartan/Spartan-ExtraLight.woff2') format('woff2'),
        url('../../fonts/Spartan/Spartan-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Spartan';
    src: url('../../fonts/Spartan/Spartan-Medium.woff2') format('woff2'),
        url('../../fonts/Spartan/Spartan-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Spartan';
    src: url('../../fonts/Spartan/Spartan-SemiBold.woff2') format('woff2'),
        url('../../fonts/Spartan/Spartan-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Spartan';
    src: url('../../fonts/Spartan/Spartan-ExtraBold.woff2') format('woff2'),
        url('../../fonts/Spartan/Spartan-ExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Spartan';
    src: url('../../fonts/Spartan/Spartan-Regular.woff2') format('woff2'),
        url('../../fonts/Spartan/Spartan-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

