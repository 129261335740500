.projectMaterialPreview-wrapper {
    background-color: $gray-100;
}

.projectMaterialPreview-item {
    padding: 20px;
    background-color: $white;
    cursor: pointer;

    &:hover {
        background-color: $gray-100;
    }
}

.projectMaterialPreview-item--title {
    color: $black;
    font-weight: 600;
    font-size: $font-size-base;
    line-height: 18px;
}

.projectMaterialPreview-item--info {
    margin: 0;
    color: rgba($black, 0.6);
    font-weight: 400;
    font-size: $font-size-sm;
    line-height: 21px;
}

.projectMaterialPreview-item--details-header {
    margin-bottom: 8px;
    position: sticky;
    top: 0;
    z-index: 2;
    padding: 13px 20px;
    background-color: $white;
    box-shadow: 0px 4px 10px rgba($black, 0.15);
}

.projectMaterialPreview-item--details-project-name {
    margin-bottom: 3px;
}

.product-list-for {
    margin-bottom: 12px;
    font-weight: 400;
    font-size: 12px;
    letter-spacing: 0.04em;
}

.projectMaterialPreview-item--details-per-vendor {
    margin-top: 6px;
    padding: 20px;
    background-color: $white;
}

.projectMaterialPreview-item--detail-vendor {
    margin-bottom: 20px;
    padding: 16px 0;
    border-bottom: 2px solid #EBE4FA;
}

.vendor-name {
    margin: 0;
    font-size: 18px;
    font-weight: 600;
}

.projectMaterialPreview-item--details-items-vendors,
.projectMaterialPreview-items-count {
    margin: 0;
    color: rgba($black, 0.6);
    font-size: $font-size-sm;
}

.shoppinglist--item--detail-total {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-top: 16px;
    margin-top: 20px;
    border-top: 2px solid #EBE4FA;
}

.total-price {
    color: rgba($black, 0.8);
    font-weight: 700;
}

.discount-price {
    margin-top: 5px;
    color: rgba($black, 0.6);
    font-size: 12px;
    text-decoration: line-through;
}

.discount-tag {
    margin-top: 5px;
    padding: 0 10px;
    display: inline-block;
    vertical-align: middle;
    background-color: rgba($yellow-800, 0.9);
    color: $black;
    opacity: 0.9;
    border-radius: 20px;
    font-size: $font-size-sm;
}

.item-amount {
    padding: 10px;
    border-radius: 30px;
    background-color: #F6F6FB;
    color: rgba($black, 0.6);
    font-weight: 400;
}

.projectMaterialPreview--order-item {
    padding: 20px 0;

    .item-price {
        color: $black;
        font-size: $h5-font-size;
    }

    .item-title {
        margin-bottom: 5px;
        font-size: $font-size-sm;
        font-weight: 600;
    }

    .item--article-number {
        color: rgba($black, 0.6);
        font-size: $font-size-sm;

        span {
            color: $black;
        }
    }

    .item-status {
        margin: 15px 0px 15px 0px;
        padding: 0 10px;
        display: inline-block;
        vertical-align: middle;
        color: $black;
        opacity: 0.9;
        border-radius: 20px;
        font-size: $font-size-sm;
        background-color: rgb(236, 236, 236);
    }
}

.no-items {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: $white;
    text-align: center;
}

.no-items--title {
    font-weight: 600;
    font-size: 24px;
}

.close-button {
    cursor: pointer;
}