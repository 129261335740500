.material--message-section {
    margin-bottom: 20px;
    padding: 20px;
    background-color: $white;
}

.material--message-section-title {
    margin-bottom: 15px;
    font-size: $font-size-base;
}

.material--message-section-messages {
    height: 200px;
    overflow-y: scroll;
    margin: 10px 0px;
    display: flex;
    flex-direction: column;
    @include media-breakpoint-up(xl) {
        height: 500px;
    }
}

.material--message-section-message-left {
    background-color: #F2F2F2;
    width: 85%;
    margin-bottom: 10px;
    padding: 10px;
    word-wrap: break-word;
    white-space: pre-line;
}

.material--message-section-message-right {
    background-color: #EFEDFD;
    width: 85%;
    margin-bottom: 10px;
    padding: 10px;
    align-self: end;
    word-wrap: break-word;
    white-space: pre-line;
}

.material--message-section-message-header {
    display: flex;
    justify-content: space-between;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.015em;
    color: #6E7587;
    opacity: 0.8;
}

.material--message-section-input-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.material--message-section-input {
    height: 40px;
    width: 220px;
    background: #F2F2F2;
    width: 100%;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 10px;
    padding-right: 38px;
    border-radius: 0;
    border: none;
    background-color: $gray-100;
    color: rgba($black, 0.8);
}

.material--message-section-file {
    right: 8px;
    bottom: 15px;
    position: absolute;
    cursor: pointer;
    display: inline-block;
}

.material--message-section-send {
    cursor: pointer;
    margin-left: 5px;
}