.MuiStepLabel-root .Mui-completed svg {
	color: var(--cui-success) !important;
}
.MuiStepLabel-root .Mui-active svg {
	color: $secondary-base !important;
}

.Mui-active .MuiStepConnector-line  {
	border-color: $secondary-base !important;
}

.Mui-completed .MuiStepConnector-line  {
	border-color: $secondary-base !important;
}

.MuiStepLabel-root .Mui-error svg {
	color: $error-red !important;
}