.add-delivery-detail {
  text-decoration: underline;
  font-size: 20px;
  font-weight: 500;

  span {
    cursor: pointer;
  }
}

.delivery-details--title {
  display: inline-block;
  margin-bottom: 17px;
  margin-top: 20px;
  cursor: pointer;
  font-weight: 600;
  font-size: $font-size-base;

  svg {
    margin-left: 15px;
  }
}

.material-delivery-details {
  &.details-opened {
    .delivery-details--title {
      svg {
        rotate: 180deg;
      }
    }
  }
}

.material-delivery-details {
  .delete {
    cursor: pointer;
  }
}

.delivery-details--table {
  .table-responsive {
    overflow: visible;
  }

  .delivery-details--table-content {
    > thead {
      background-color: $table-striped-bg;
      border-bottom: 1px solid $border-color;

      th {
        vertical-align: middle;
        padding: 6px 18px;
        font-size: $font-size-sm;
        font-weight: 500;
      }

      + tbody {
        td:first-child,
        td:nth-child(2) {
          padding: 8px;
        }
        td,
        th {
          padding-left: 18px;
          padding-right: 18px;
          vertical-align: middle;
        }
      }
    }
  }

  .address-cell {
    p {
      width: 210px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      transition: all 0.2s linear;
      /*&:hover {
                &:after{
                    content:attr(data-text);
                    display:block;
                    margin-top:-1.25rem;
                    width: 400px;
                    position: absolute;
                    left:auto;
                    top:auto;
                    padding: 10px;
                    box-shadow: 0 2px 4px 0 rgba($black,.28);
                    background: $white;
                    color:$black;
                    overflow: visible;
                    white-space: normal;
                    word-wrap: break-word;
                  }
            }*/
    }

    .add-new-address-button:hover {
      background: $white;
      border-color: $white;
    }
  }

  .form-check {
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .quantity-cell {
    .form-control {
      max-width: 80px;
      margin: 0 auto;
      padding: 0;
      border: none;
      border-radius: 0;
      border-bottom: 1px solid $border-color;
      background-color: transparent;
      text-align: right;
    }
  }
}

.add-new-address-modal {
  .modal-header {
    padding: 32px 50px;
  }

  .modal-body {
    padding: 50px 50px 30px 50px;
  }

  .modal-footer {
    padding: 26px 50px;
  }

  .form-floating {
    margin-bottom: 20px;
  }

  .address-optional {
    margin-top: 20px;
    margin-bottom: 8px;
    font-weight: 600;
  }
}

.quantity-cell-warning {
  outline: 4px solid $danger-base;
}
