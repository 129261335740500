.product-view--details {
    margin-bottom: 20px;
    .short-description {
        font-size: $font-size-lg;
    }

    .show-more, .show-less {
        cursor: pointer;
        font-weight: 500;
    }

    .show-less {
        margin-top: 28px;
        svg {
            margin-left: 8px;
        }
    }

    .show-more--text {
        padding-top: 22px;
        border-top: 1.6px solid #E7E7E9;
        color:rgba($black, 0.6);
    }
}