.select-language-item {
  .dropdown-menu {
    box-shadow: $box-shadow;
    li {
      padding: 0 10px;
      &:not(:last-child) {
        .dropdown-item {
          margin-bottom: 5px;
        }
      }
    }

    .dropdown-item {
      padding: 5px 6px;
      &:focus-visible {
        background-color: $white;
        outline: 4px solid rgba($light-violet, 0.6);
      }
      &:hover {
        background-color: $white;
      }
    }
  }

  &.show {
    .selected-language {
      color: $yellow;
      svg {
        path {
          fill: $yellow;
        }
      }

      .arrow-icon {
        rotate: 180deg;
        path {
          fill: $yellow;
        }
      }
    }
  }
}

li.nav-item.dropdown.select-language-item {
  list-style-type: none !important;
}

.selected-language {
  margin-left: 8px;
  span {
    font-size: $font-size-sm;
  }
  > svg {
    margin-right: 5px;
  }

  .arrow-icon {
    margin-left: 5px;
  }

  &:focus-visible {
    color: $white;
  }

  &:hover {
    color: $yellow;
    svg {
      path {
        fill: $yellow;
      }
    }

    .arrow-icon {
      path {
        fill: $yellow;
      }
    }
  }
}
