.icon-dropdown {
  &:hover,
  &:focus,
  &:active {
    background-color: $yellow-300;
    svg {
      path {
        stroke: transparent;
      }
    }
  }

  &.show {
    background-color: $yellow;
  }

  &.borderless {
    &.show,
    &:hover,
    &:focus {
      background-color: transparent;
    }
    .dropdown-toggle {
      border: none !important;
    }
  }

  &.icon-right {
    .dropdown-toggle {
      div {
        p {
          margin-right: 14px;
          text-align: left;
        }
      }
    }
  }

  &.icon-left {
    .dropdown-toggle {
      div {
        p {
          margin-left: 14px;
        }
      }
    }
  }

  .dropdown-toggle {
    padding: 11px 20px;
    display: flex;
    flex-direction: column;
    background: transparent;
    color: $black;
    border: 1px solid #828486 !important;
    border-radius: 4px;
    box-shadow: none !important;
    &:after {
      display: none;
    }

    .dropdown-toggle--title {
      margin-bottom: 7px;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
    }

    div {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  .dropdown-menu {
    .dropdown-item {
      padding: 8px 21px;
      border-left: 7px solid transparent;
      color: $black;
      cursor: pointer;

      &.active {
        border-left-color: #75768a;
        background-color: white;
        color: $black;
        font-weight: 700;
        letter-spacing: 0.04em;
      }
      &:hover {
        background-color: $yellow;
        border-left-color: $yellow;
      }
    }
  }
}

.searchable-item {
  &:active {
    background-color: inherit !important;
    color: inherit !important;
  }
}

.searchable-dropdown {
  max-height: 30vh;
  overflow-y: scroll;
  .searchable-dropdown-content {
    border: 1px solid #ced4da;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    z-index: 99;
    position: absolute;
    background-color: white;
    max-height: 200px;
    overflow-y: scroll;
  }
}
