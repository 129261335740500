.backoffice-top-navigation {
  padding: 18px 25px 18px 9px;
  background-color: $white;
  box-shadow: $box-shadow;

  .select-language-item {
    .selected-language {
      margin-left: -12px;
    }
    svg path {
      fill: $black;
    }
    &:hover {
      svg path {
        fill: $yellow;
      }
    }
  }
}

.backOffice-userDropdown {
  .login-icon {
    padding: 0;
  }

  .dropdown-menu {
    padding: 0;
  }

  .dropdown-item-text {
    padding: 24px;
    background-color: $white;

    .user-loggedIn-icon {
      width: 56px;
      height: 56px;
      background: rgba(110, 117, 135, 0.7);
    }
  }

  .user-info {
    padding-left: 16px;
    margin-bottom: 26px;
  }

  .user-info--name {
    margin-bottom: 5px;
    .family-name {
      margin-left: 4px;
    }
  }

  .user-info--email {
    margin: 0;
  }
}

.backoffice-top-row {
  .btn-table {
    padding: 10px 24px;
    text-transform: uppercase;

    svg {
      polygon {
        fill: $secondary-base;
      }
    }
    &__option {
      text-transform: uppercase;
      font-size: 12px;
      text-decoration: underline;
      text-transform: uppercase;
      padding: 0px;

      &--cell-action {
        color: $violet;
      }
    }
  }
}

.sidebar-nav .nav-group.show {
  background-color: $primary-base;
}

.deactivate-drawer {
  min-width: 580px;
  background: #fff;
  &__body {
    padding-inline: 80px;
  }
}

.edit-drawer {
  min-width: 580px;
  background: #fff;
  &__header-type {
    font-size: 14px;
  }
  &__body {
    padding-inline: 20px;
  }
  &__body-sideicons {
    padding-inline: 10px 50px;
  }
}
