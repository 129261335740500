.filters-section {
  padding: 16px 0;
  border: 3px solid #e4e6f2;

  @include media-breakpoint-up(lg) {
    padding: 31px 0;
  }

  @include media-breakpoint-down(sm) {
    border: 0;
    padding: 0 0 16px;
  }
}

.flex-gap {
  display: inline-flex;
  flex-wrap: wrap;
}

.flex-gap > div {
  margin: 6px;
}

.show-more--trigger {
  padding: 0 24px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-decoration: underline;
  text-transform: uppercase;
  cursor: pointer;

  @include media-breakpoint-up(lg) {
    font-size: 16px;
    line-height: 19px;
  }
}

.filters-section--title {
  margin-bottom: 5px;
  padding: 0 14px 13px 14px;
  border-bottom: 3px solid #e4e6f2;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;

  @include media-breakpoint-up(lg) {
    padding: 0 20px 26px 20px;
    font-size: 24px;
    line-height: 27px;
  }
}

.subcategory-group {
  padding: 0 14px;
  margin-bottom: 32px;

  @include media-breakpoint-up(lg) {
    padding: 0 29px;
  }

  .list-group-item {
    padding: 0 0 16px 0;
    background-color: transparent;
    border: none;
    color: rgba($color: $black, $alpha: 0.7);
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
  }
}

.category-selected {
  color: $violet !important;
}

.subcategory-group--title-wrapper {
  margin-bottom: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .subcategory-group--title {
    margin: 0;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
  }

  span {
    color: rgba($color: $black, $alpha: 0.7);
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
  }
}

.filters--top-section {
  margin-top: 30px;
  padding: 26px 24px 16px;
  border-top: 3px solid $white;
  border-bottom: 3px solid #e4e6f2;

  @include media-breakpoint-down(sm) {
    margin-top: 0px;
  }
}

.filters--top-section--title {
  margin: 0;
  font-weight: 500;
  font-size: 22px;
  line-height: 27px;

  @include media-breakpoint-up(xl) {
    font-weight: 500;
    font-size: 26px;
    line-height: 31px;
  }

  svg {
    margin-right: 8px;
  }
}

.clear-filters {
  padding: 4px 8px;
  border-radius: 35px;
  color: $violet;
  background: rgba(228, 230, 242, 0.4);
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  cursor: pointer;
}

.clear-all-filters {
  padding: 4px 8px;
  margin-right: 24px;
  border-radius: 35px;
  color: $violet;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  cursor: pointer;
}

.show-more-filters--trigger {
  margin-top: 24px;
  padding: 0 46px;
  color: $violet;
  font-weight: 500;
  font-size: 14px;
  text-decoration: underline;
  line-height: 17px;
  cursor: pointer;

  @include media-breakpoint-up(lg) {
    font-size: 16px;
    line-height: 19px;
  }
}

.filter {
  .form-check {
    margin: 15px 19px 0 19px;
  }
}

.filter--title-wrapper {
  padding: 12px 0 0 0;

  @include media-breakpoint-up(lg) {
    padding: 32px 0 0 0;
  }
}

.filter--title {
  padding: 0 24px;
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
}

.filter--input-wrapper {
  padding: 4px 24px;
}

.filter--input-wrapper-attributes {
  padding: 4px 0px;
}

.searchWrapper {
  border-radius: 0px !important;
  border: 1px solid var(--cui-border-color) !important;
  min-height: calc(2.25rem + 2px) !important;
  font-size: 1rem !important;
}

.filter--input-value {
  min-width: 100px;
  max-width: 150px;
  width: 100%;
  white-space: nowrap;
}

.filter--input-multiselect {
  height: 48px;
  border: 1px solid rgba(130, 132, 134, 0.3);
  padding: 6px 132px 0 0;
  box-shadow: none !important;
  border-radius: 0;
}

.form-multi-select-selection-tags {
  padding: 5px 35px 5px 5px !important;
  cursor: pointer;
}

.form-multi-select-search:placeholder-shown {
  width: auto;
  margin: 0;
}

.form-multi-select-with-cleaner {
  padding-right: 0 !important;

  > div {
    cursor: pointer;
  }
}

.picker .dropdown-menu {
  padding-top: 8px;
  padding-bottom: 8px;
}

.form-multi-select {
  border-radius: 0;
  .dropdown-menu {
    width: 100%;
  }
}

.filter--input-attribute {
  margin-bottom: 36px;
}

.filter--top-filter {
  padding: 9px 13px;
  margin-right: 24px;
  border-radius: 30px;
  background: $yellow;
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
  cursor: pointer;
}

.filter--top-filter-vendor {
  padding: 9px 13px;
  margin-right: 24px;
  border-radius: 30px;
  background: $light-violet;
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
  cursor: pointer;
}

.filter--top-clear {
  right: -5px;
  top: -5px;
  cursor: pointer;
  background-color: rgb(196, 197, 198);
  border-radius: 50%;
  opacity: 0.8;
  &:hover {
    opacity: 1;
  }
}

.filter-mobile-clear {
  color: $violet;
  background-color: rgba($violet, 0.2);
  border-radius: 50%;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px;
}

.filter--top-filter-price {
  padding: 9px 13px;
  margin-right: 24px;
  border-radius: 30px;
  background: #05faef;
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
  cursor: pointer;
}

.filter--top-filter-attribute {
  padding: 9px 13px;
  margin-right: 24px;
  border-radius: 30px;
  background: #e5e4e2;
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
  cursor: pointer;
}

.filter--category-item {
  cursor: pointer;

  &:hover {
    color: $violet;
  }
}

.filter--category-count {
  cursor: pointer;
  font-weight: bold;
  font-size: 0.8rem;
  color: black;

  &:hover {
    color: $violet;
  }
}

.form-multi-select-options {
  min-height: 50px;
}

.form-multi-select-option.form-multi-select-option-with-checkbox::before {
  top: 0.4rem;
}

.mobile-filters-section {
  .filter-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    padding: 5px;
    background-color: $gray-100;
  }
}
