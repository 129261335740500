@import '../components/bIncrementDecrementInput';
@import '../components/_bdropdown';

.product-view--units-quantity {
    .unit-info {
        margin-top: 10px;
        margin-bottom: 24px;
        @include media-breakpoint-up(xl) {
            display: flex;
            justify-content: flex-end;
            .d-flex {
                width: 50%;
            }
        }
    }

    .minimum-quantity {
        margin-right: 8px;
        display: flex;
    }

    .dropdown-wrapper {
        .selected-item {
            width: 150px;
        }
        &.has-error {
            border-color: $danger-base;
            .dropdown-icon {
                border-color: $danger-base;
            }
        }
    }
}

.unit-quantity-info {
    cursor: pointer;
    flex-shrink: 0;
    svg {
        margin-right: 5px;
    }

    span {
        color: rgba($black, 0.6);
        font-size: $font-size-sm;
        line-height: 16px;
        text-transform: uppercase;
        text-decoration: underline;
    }
    &:hover {
        svg {
            path {
                fill: $purple;
            }
        }
        span {
            color: $purple;
        }
    }
}

.unit-information--modal {
    .modal-dialog {
        max-width: 740px;
    }
    .modal-header {
        border: none;
    }

    thead {
        th {
            color: rgba($black, 0.6);
            font-weight: 400;
            &.ordering-unit {
                padding-left: 16px;
                @include media-breakpoint-up(lg) {
                    padding-left: 32px;
                }
            }
        }
    }
    tbody {
        td {
            color: rgba($black, 0.6) !important;
            &.unit-type {
                padding-left: 16px;
                @include media-breakpoint-up(lg) {
                    padding-left: 32px;
                }
                font-weight: 600;
            }
            &.unit-price {
                color: $black !important
            }
        }
    }
}