
.nested-rows-renderer {
  &__button {
  border: 1px dashed $gray-base;
}

  &__multiple {
    font-size: 14px;
    padding: 0px;
  }
} 
.font-capitalize {
  text-transform: uppercase;
}