.testimonial-section {
    margin-top: 40px;
    padding: 30px 0;
    background: $yellow-800;
    @include media-breakpoint-up(lg) {
        padding: 73px 0 122px 0;
    }

    .container {
        @include media-breakpoint-up(xxl) {
            max-width: 1237px;
        }
        
        position: relative;
        &:before {
            content: '';
            z-index: 1;
            width: 100%;
            height: 100%;
            position: absolute;
            right: 0;
            top: 8%;
            left: 5%;
            transform: translateX(-5%);
            background: url('../../assets/backgrounds/testimonial/testimonial-bg.png') no-repeat;
            background-size: 135%;
            @include media-breakpoint-up(lg) {
                top: 22%;
                background-size: contain;
            }
            @include media-breakpoint-up(xxl) {
                top: 15%;
                transform: none;
            }
        }
    }
}

.testimonial-section--title {
    padding-bottom: 25px;
    border-bottom: 1px solid rgba($primary-base, 0.3);
    color: $primary-base;
    font-size: 28px;
    line-height: 31px;
}

.testimonial {
    margin-top: 32px;
    position: relative;
    z-index: 2;
    @include media-breakpoint-up(lg) {
        margin-top: 54px;
        max-width: 512px;
    }
}

.testimonial--content {
    margin-bottom: 16px;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
}

.testimonial--editor {
    margin: 0;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    font-family: $spartan-font;
}