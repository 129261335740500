.admin-panel-container {
    padding-top: 1rem;
    width: 80vw !important;
    min-height: 90vh !important;
    padding-left: auto !important;
    padding-right: auto !important;
}

.input-group-text {
    background-color: $primary-base !important;
}

.color-white {
    color: $white;
}

.color-yellow {
    color: $secondary-base !important;
}

.input-group-text {
    background-color: $primary-base;
}

.center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.grid {
    height: 100%;
    width: 100vw;
}

.ag-theme-alpine {
    width: 100%;
    height: calc(100vh - 185px);
    --ag-header-height: 60px;
    --ag-row-hover-color: #F6F3FB;
    --ag-selected-row-background-color: #FFFFDD;
    --ag-checkbox-checked-color: #{$secondary-base};

    & .ag-checked {
    --ag-checkbox-background-color: black;
  }
}

