@import '../atoms/_breadcrumb';
@import '_filters-section';
@import '_product-card';

.product-list-page {
  padding-top: 25px;

  .spinner--wrapper {
    position: absolute;
    left: 50%;
    top: 5%;
    transform: translateX(-50%) translateY(0%);
  }

  .icon-dropdown {
    &.icon-right {
      .dropdown-toggle {
        div {
          p {
            margin-right: 22px;
          }
        }
      }
    }
  }
}

.product-list-page--top-section .container {
  padding: 0 16px;

  @include media-breakpoint-up(lg) {
    padding: 0 32px;
  }
  .product-list-page--top-section--clear-filter {
    cursor: pointer;
    background-color: rgb(196, 197, 198);
    border-radius: 35px;
    opacity: 0.8;
    margin-top: 3px;
    &:hover {
      opacity: 1;
    }
  }
}

.product-list-wrapper {
  position: relative;
}

.product-list--top {
  margin-bottom: 20px;
}

.favorite {
  cursor: pointer;

  svg {
    margin-right: 14px;
  }

  span {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
  }
}

.results-list {
  font-weight: 500;
  font-size: 28px;
  line-height: 31px;

  @include media-breakpoint-down(sm) {
    font-size: 20px;
    line-height: 24px;
  }
}

.product-list-page--main .container {
  margin-top: 25px;
  padding: 22px 16px;
  background-color: $white;
  box-shadow: 0px 4px 40px rgba($black, 0.25);
  border-radius: 4px;

  @include media-breakpoint-down(xl) {
    max-width: calc(100% - 24px);
  }

  @include media-breakpoint-up(lg) {
    padding: 25px 32px;
  }
}

.top-filters-wrapper {
  input {
    margin-bottom: 10px;
    width: 100%;
    height: 35px;
    padding-right: 25px;
    border: none;
    background: #f7f7fc;
    border-radius: 30px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
      max-width: 114px;
    }

    &:focus {
      border: none;
      box-shadow: none;
      background: #f7f7fc;
    }

    &.filter4 {
      @include media-breakpoint-up(lg) {
        max-width: 245px;
      }
    }
  }

  .clear-filters {
    flex-shrink: 0;
  }

  .filter-wrapper {
    position: relative;

    @include media-breakpoint-up(lg) {
      margin-right: 11px;
    }

    .clear-filter {
      position: absolute;
      right: 20px;
      top: 4px;
      cursor: pointer;
    }
  }
}

.fetching-spinner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.optionListContainer {
  padding: 0 20px;
  left: 0;
  right: 0;
  .highlightOption {
    background-color: $white;
    color: $black;
  }
}

.multiSelectContainer {
  ul {
    border-radius: 0;
  }
  .optionListContainer {
    border-radius: 0;
  }
  li:hover {
    background-color: $yellow !important;
    color: $black !important;
  }

  .chip {
    background-color: $yellow;
    color: $black;
    white-space: pre-wrap;
    border-radius: 0;
  }

  .closeIcon {
    filter: grayscale(1) invert(1);
  }
}
