@import '_material--delivery-details';

.changed-value {
    background-color: rgba($yellow-800, 0.9) !important;
}

.changes-message {
    margin-left: 40px;
    background: #fff6cc !important;
    
    svg {
        width: 30px;
    }
}