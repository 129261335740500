.loggedin-button {
  position: relative;
}

.user-popover {
  opacity: 0;
  display: none;
  width: 563px;
  position: absolute;
  right: 0;
  top: 100%;
  background-color: $white;
  box-shadow: $box-shadow;
  transition: opacity 0.3s ease-in-out;
  &.is-opened {
    opacity: 1;
    display: block;
    z-index: 1000;
  }
  .copy-icon {
    margin-left: 8px;
    cursor: pointer;
    svg path {
      fill: transparent !important;
    }
  }

  @media screen and (max-width: $screen-sm) {
    width: auto;
    min-width: 95vw;
  }
}

.user-popover--head {
  padding: 14px 21px 21px 21px;
  background-color: #482fb1;

  .given-name,
  .family-name {
    margin-bottom: 5px;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
  }

  .given-name {
    margin-right: 4px;
  }

  .user--email {
    margin: 0;
    font-size: $font-size-sm;
    font-weight: 400;

    @media screen and (max-width: $screen-sm) {
      word-break: break-all;
      overflow-wrap: break-word;
      width: 100%;
    }
  }

  .user-loggedIn-icon {
    margin-right: 8px;
    background-color: rgba($white, 0.2) !important;
    color: $white !important;
  }
}

.user--role {
  display: inline-block;
  padding: 8px 14px;
  border-radius: 22px;
  background-color: rgba($white, 0.2);
}

.user-popover--content {
  padding: 24px 21px 21px 21px;
}

.user-popover--footer {
  border-top: 1px solid rgba($black, 0.3);
}

.popover-sign-out {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  div {
    cursor: pointer;
  }
  span {
    color: rgba($black, 0.7);
    font-family: $helvetica-font;
    font-weight: 400;
  }
  svg {
    margin-left: 15px;
    path {
      fill: #000;
    }
  }
}

.user-tabs {
  .nav {
    align-items: center;
    justify-content: space-between;
  }

  .nav-item {
    text-align: center;
    flex: 1 0;
  }

  .nav-link {
    padding: 9px;
    border-bottom: 4px solid rgba($black, 0.02);
    color: $black;
    font-family: $spartan-font;
    font-weight: 500;
    &.active {
      border-bottom-color: $yellow;
      color: $black;
      font-weight: 700;
    }
  }

  .tab-content {
    a,
    span {
      margin-top: 24px;
      display: inline-block;
      color: rgba($black, 0.7);
    }
  }
  @media screen and (max-width: $screen-sm) {
    max-height: 55vh;
    overflow-y: auto;
  }
}

.mobile-popover-options {
  max-height: 64vh;
  overflow-y: auto;
  margin-left: 50px;
  h4 {
    font-size: $font-size-lg;
    font-weight: 600;
    border-bottom: 3px solid $yellow-500;
    max-width: -moz-fit-content;
    max-width: fit-content;
  }
}
