.material--total-prices {
  .table {
    td {
      padding: 5px 0;
      background-color: $white;
      color: rgba($black, 0.6);
      font-size: 12px;
      + td {
        text-align: right;
      }
      &.material--total-prices-header {
        font-size: 16px;
      }
      &:nth-child(2) {
        vertical-align: bottom;
      }
    }

    tr {
      &:nth-last-child(2) {
        td {
          padding-bottom: 20px;
        }
      }
    }

    .total-price {
      td {
        border-top: 1px solid $yellow;
        font-size: $font-size-base;
        color: $black;
        min-width: 100px;
      }
    }

    .total-vat {
      td {
        border-top: 1px solid rgba($black, 0.2);
        font-size: 12px;
        color: rgba($black, 0.6);
      }
    }
  }
}
