.warning-message {
    margin-top: 12px;
    padding: 10px;
    background: rgba($yellow, 0.6);
    border-radius: 6px;
    font-size: $font-size-sm;
    font-weight: 500;
    strong {
        text-decoration: underline;
        cursor: pointer;
    }

    svg {
        margin-right: 6px;
    }
}