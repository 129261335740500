.note-section--title {
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    color: rgba($black, 0.6);
    font-size: $font-size-sm;
    font-weight: 500;
}

.note-section--trigger, .note-section--modal-attach-file {
    display: inline-flex;
    align-items: center;
    color: $violet;
    font-size: $font-size-sm;
    cursor: pointer;
    svg {
        margin-right: 8px;
    }

    span {
        text-decoration: underline;
        font-weight: 500;
    }
}

.note-section--modal {
    .modal-dialog {
        --cui-modal-width: 600px;
    }

    .modal-body {
        padding: 20px 20px 40px 20px;
    }
}

.note-section--modal-title {
    margin-bottom: 20px;
    font-size: 20px;
}

.note-section--modal-attached-files {
    margin: 16px 0;
    overflow: auto;
    padding: 5px;
}

.note-section--modal-file {
    margin-right: 8px;
    padding: 6px 16px;
    border-radius: 30px;
    background-color: $gray-100;
    color: rgba($black, 0.6);
    font-size: $font-size-sm;

    svg {
        cursor: pointer;
    }
}

.note-setion--content {
    border-top: 1px solid $border-color;

    p {
        margin: 8px 0;
        color: rgba($black, 0.6);
        font-size: $font-size-sm;
    }
}

.note-section--images {
    img {
        margin-right: 5px;
    }
    overflow: auto;
    padding: 5px;
}

.note-section-mf-side {
    margin-top: 40px;

    .note-section--content-wrapper {
        padding: 20px;
        background-color: $gray-100;

        &.private-comment {
            background-color: $yellow-500;

            .note-section--title {
                color: $primary;
            }

            .note-setion--content {
                border-color: $primary;
                p {
                    color: $primary
                }
            }
        }
    }
}