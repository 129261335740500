.shopping-list-offcanvas-info-bold {
    color: rgba($black, 0.6);
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    strong {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: rgba($black, 1);
    }
}

.shopping-list-offcanvas-warning-project {
    background-color: $secondary-base;
    padding: 5px;
    margin-bottom: 3px;
    border-radius: 6px;
}

.bg-white {
    background-color: $white;
}

.shopping-list-offcanvas-title {
    max-width: 350px;
}